<template>
    <div>
        <div class="folder container-fluid">
            <p @click="backBtn()" class="back-text"><span><i class="fa fa-chevron-left"></i></span>Back</p>

            <div class="holder">
                <div class="row">
                    <div class="col-md-6">
                        <div class="left-text">
                            <h2>Generate voucher</h2>
                            <h6>Select the voucher option that best suits your requirements</h6>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="right-options">
                            <div class="cardd" @click="optionss('standard')">
                                <div>
                                    <h5 class="d-flex justify-content-between">
                                      <span>Standard buy</span>
                                      <span><i class="fa fa-chevron-right"></i></span>
                                    </h5>
                                    <h6>Best for one-time purchases or small quantities (upto 5).</h6>
                                </div>
                            </div>

                            <div class="cardd" @click="optionss('bulk')">
                                <div>
                                    <h5 class="d-flex justify-content-between">
                                      <span>Bulk buy</span>
                                      <span><i class="fa fa-chevron-right"></i></span>
                                    </h5>
                                    <h6>Ideal for purchasing multiple vouchers in bulk (upto 100 vouchers).</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import transactionPin from "../services/transactionPin";

export default {
   name: "VoucherHome",
   data() {
      return {
         loading: false,
      }
   },
   mounted() {
   },
   methods: {
      backBtn() {
        this.$router.push({path: `/user/dashboard`});
      },
      optionss (route) {
         this.$router.push({path: `voucher-options/${route}`});
      },
      
      async singleVoucher() {
         this.loadingPinSet = true;
         const body = {};
         try {
            await transactionPin.setTransactionPin(JSON.stringify(body)).then((res) => {
               console.log("Pin setting", res);
               this.loadingPinSet = false;
               if (res.success) {
                  this.$toast.success("SUCCESS, TRANSACTION PIN SET");
                  this.setpinPassword = false;
                  this.setpin = false;
               }
               if (res.error) {
                  return this.$toast.error("ERROR:", res.error);
               }
            }).catch((err) => {
               console.log(err);
               // this.loadingPinSet = false;
            });
         } finally {
            // console.log("closed");
         }
      },
   },
}
</script>


<style scoped lang="scss">

.back-text {
   font-family: Lato;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #535862;
   margin-bottom: 20px !important;
   cursor: pointer;
   span {
      padding-right: 10px;
   }
}
.left-text {
   h2 {
      font-family: Lato;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      color: #2B3352;
      text-align: left;
   }
   h6 {
      font-family: Lato;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      // letter-spacing: 0%;
      color: #2B3352;
      margin-bottom: 30px;
   }
}

.folder {
   background: #ffffff;
   padding: 30px 15px;
}

.cardd {
   border: 1px solid #E9EAEB;
   border-radius: 12px;
   padding: 16px;
   cursor: pointer;
   margin-bottom: 15px;
   h5 {
      font-family: Lato;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #2B3352;
   }
   h6 {
      font-family: Lato;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #555C74;

   }
}
</style>